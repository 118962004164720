<!--原材料合同台账维护-品级-->
<template>
    <div class="maintain_nx ">
        <div class="content_box">
            <table>
                <tbody>
                    <tr>
                        <th colspan="4" class="tHeader">
                            原材料采购表
                        </th>
                    </tr>
                    <tr>
                        <th>合同编号：</th>
                        <td>{{ formData.contract_no }}</td>
                        <th>合同名称：</th>
                        <td>{{ formData.contract_name }}</td>
                    </tr>
                    <tr>
                        <th>供货商：</th>
                        <td colspan="3">
                            {{ formData.supplier_name }}
                        </td>
                    </tr>
                    <tr>
                        <th colspan="4" class="tHeaderBtn">
                            <h4 class="el_left">
                                物料信息表
                            </h4>
                            <el-button type="primary" class="el_right" @click="addForm">
                                添加
                            </el-button>
                        </th>
                    </tr>
                    <tr>
                        <td colspan="4" class="tdStyle">
                            <template v-for="(item, index) in formData.materials">
                                <table :key="index" v-if="item.is_delete == 0" :class="{'invalid':item.is_enabled == 0}">
                                    <tbody v-if="item.rootid">
                                        <tr>
                                            <th>原材料名称：</th>
                                            <td>{{ item.type_name }}</td>
                                            <th>原材料子类：</th>
                                            <td>{{ item.child_type_name }}</td>
                                        </tr>
                                        <tr>
                                            <th>规格型号：</th>
                                            <td>{{ item.spec_name }}</td>
                                            <th>原材料品级：</th>
                                            <td>{{ item.grade_name }}</td>
                                        </tr>
                                        <tr>
                                            <th>货源地：</th>
                                            <td>{{ item.source_name }}</td>
                                            <th>供应站点：</th>
                                            <td>{{ item.station_name }}</td>
                                        </tr>
                                        <tr>
                                            <th>是否存在超吨：</th>
                                            <td class="tdStyle" colspan="3">
                                                <div class="overWeightInput">
                                                    <el-radio v-model="item.is_exist_overweight" :label="true" disabled>
                                                        是
                                                    </el-radio>
                                                    <el-radio v-model="item.is_exist_overweight" :label="false" disabled>
                                                        否
                                                    </el-radio>
                                                    <template v-if="item.is_exist_overweight">
                                                        超吨值：
                                                        <el-input
                                                            placeholder="请输入"
                                                            type="number"
                                                            v-model="item.overweight_value"
                                                            readonly
                                                            style="width: 0.9rem!important;"
                                                        ></el-input>
                                                        吨
                                                    </template>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <th>含税价(不超吨)(元)：</th>
                                            <td>{{ item.unit_price }}</td>
                                            <th>含税价(超吨)(元)：</th>
                                            <td>{{ item.overweight_price }}</td>
                                        </tr>
                                        <tr>
                                            <th>税率（%）：</th>
                                            <td colspan="3">
                                                {{ item.tax_rate }}
                                            </td>
                                        </tr>
                                        <template v-if="formData.transport_contractor==0">
                                            <tr>
                                                <th>运输单价(不超吨)(元)：</th>
                                                <td>{{ item.transport_price }}</td>
                                                <th>运输单价(超吨)(元)：</th>
                                                <td>{{ item.overweight_transport_price }}</td>
                                            </tr>
                                            <tr>
                                                <th>税率（%）：</th>
                                                <td colspan="3">
                                                    {{ item.transport_rate }}
                                                </td>
                                            </tr>
                                        </template>
                                        <tr class="state">
                                            <th>
                                                状态
                                            </th>
                                            <td>
                                                <el-radio-group v-model="item.is_enabled" @change="modify(index)">
                                                    <el-radio :label="1">
                                                        有效
                                                    </el-radio>
                                                    <el-radio :label="0">
                                                        失效
                                                    </el-radio>
                                                </el-radio-group>
                                            </td>
                                            <template v-if="item.is_enabled === 1">
                                                <th>
                                                    生效时间
                                                </th>
                                                <td class="tdStyle">
                                                    <el-date-picker
                                                        v-model="item.effective_date"
                                                        type="datetime"
                                                        placeholder="选择生效时间"
                                                        value-format="yyyy-MM-dd HH:mm:ss"
                                                        @change="modify(index)"
                                                    >
                                                    </el-date-picker>
                                                </td>
                                            </template>
                                            <template v-else>
                                                <th>
                                                    失效时间
                                                </th>
                                                <td class="tdStyle">
                                                    <el-date-picker
                                                        v-model="item.cutoff_date"
                                                        type="datetime"
                                                        placeholder="选择失效时间"
                                                        value-format="yyyy-MM-dd HH:mm:ss"
                                                        @change="modify(index)"
                                                    >
                                                    </el-date-picker>
                                                </td>
                                            </template>
                                        </tr>
                                    </tbody>
                                    <tbody v-else>
                                        <tr>
                                            <th>原材料名称：</th>
                                            <td class="tdStyle" @click="selectType(index, item)">
                                                <el-input
                                                    v-model="item.type_name"
                                                    readonly
                                                    placeholder="请选择原材料"
                                                    @change="modify(index)"
                                                    :disabled="item.is_enabled == 0"
                                                >
                                                    <el-button slot="append">
                                                        选择
                                                    </el-button>
                                                </el-input>
                                            </td>
                                            <th>原材料子类：</th>
                                            <td>{{ item.child_type_name }}</td>
                                        </tr>
                                        <tr>
                                            <th>规格型号：</th>
                                            <td>{{ item.spec_name }}</td>
                                            <th>原材料品级：</th>
                                            <td class="tdStyle">
                                                <el-select
                                                    v-model="item.grade_code"
                                                    @change="chooseGrade(item)"
                                                    :disabled="item.is_enabled == 0"
                                                >
                                                    <el-option
                                                        v-for="grade in mapGradeList[getGradeKey(item)]"
                                                        :key="grade.grade_code"
                                                        :label="grade.grade_name"
                                                        :value="grade.grade_code"
                                                    >
                                                    </el-option>
                                                </el-select>
                                            </td>
                                        </tr>
                                        <tr>
                                            <th>货源地：</th>
                                            <td class="tdStyle" @click="selectSource(index, item)">
                                                <el-input
                                                    v-model="item.source_name"
                                                    readonly
                                                    placeholder="请选择货源地"
                                                    :disabled="item.is_enabled == 0"
                                                >
                                                    <el-button slot="append">
                                                        选择
                                                    </el-button>
                                                </el-input>
                                            </td>
                                            <th>供应站点：</th>
                                            <td class="tdStyle" @click="selectStation(index, item)">
                                                <el-input
                                                    v-model="item.station_name"
                                                    readonly
                                                    placeholder="请选择供应站点"
                                                    @change="modify(index)"
                                                    :disabled="item.is_enabled == 0"
                                                >
                                                    <el-button slot="append">
                                                        选择
                                                    </el-button>
                                                </el-input>
                                            </td>
                                        </tr>
                                        <tr>
                                            <th>是否存在超吨：</th>
                                            <td class="tdStyle">
                                                <div class="overWeightInput">
                                                    <el-radio v-model="item.is_exist_overweight" :label="true" :disabled="item.is_enabled == 0">
                                                        是
                                                    </el-radio>
                                                    <el-radio v-model="item.is_exist_overweight" :label="false" :disabled="item.is_enabled == 0">
                                                        否
                                                    </el-radio>
                                                    <template v-if="item.is_exist_overweight">
                                                        超吨值：
                                                        <el-input
                                                            placeholder="请输入"
                                                            type="number"
                                                            v-model="item.overweight_value"
                                                            :disabled="item.is_enabled == 0"
                                                            style="width: 0.9rem!important;"
                                                        ></el-input>
                                                        吨
                                                    </template>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <th>含税价(不超吨)(元)：</th>
                                            <td class="tdStyle">
                                                <el-input
                                                    v-model="item.unit_price"
                                                    placeholder="请输入含税价不超吨"
                                                    @change="modify(index)"
                                                    :disabled="item.is_enabled == 0"
                                                ></el-input>
                                            </td>
                                            <th>含税价(超吨)(元)：</th>
                                            <td class="tdStyle">
                                                <el-input
                                                    v-model="item.overweight_price"
                                                    placeholder="请输入含税价超吨"
                                                    @change="modify(index)"
                                                    :disabled="item.is_enabled == 0||!item.is_exist_overweight||item.transport_id!=''"
                                                ></el-input>
                                            </td>
                                        </tr>
                                        <tr>
                                            <th>税率（%）：</th>
                                            <td class="tdStyle" colspan="3">
                                                <el-input v-model="item.tax_rate" placeholder="请输入税率" :disabled="item.is_enabled == 0"></el-input>
                                            </td>
                                        </tr>
                                        <template v-if="formData.transport_contractor==0">
                                            <tr>
                                                <th>运输含税单价(不超吨)(元)：</th>
                                                <td class="tdStyle">
                                                    <el-input
                                                        v-model="item.transport_price"
                                                        placeholder="请输入运输含税单价"
                                                        @change="modify(index)"
                                                        :disabled="item.is_enabled == 0"
                                                    ></el-input>
                                                </td>
                                                <th>运输含税(超吨)(元)：</th>
                                                <td class="tdStyle">
                                                    <el-input
                                                        v-model="item.overweight_transport_price"
                                                        placeholder="请输入运输含税单价"
                                                        @change="modify(index)"
                                                        :disabled="item.is_enabled == 0||!item.is_exist_overweight"
                                                    ></el-input>
                                                </td>
                                            </tr>
                                            <tr>
                                                <th>税率（%）：</th>
                                                <td class="tdStyle" colspan="3">
                                                    <el-input
                                                        v-model="item.transport_rate"
                                                        placeholder="请输入税率"
                                                        @change="modify(index)"
                                                        :disabled="item.is_enabled == 0"
                                                    ></el-input>
                                                </td>
                                            </tr>
                                        </template>

                                        <tr class="state">
                                            <th>
                                                状态
                                            </th>
                                            <td>
                                                <el-radio-group v-model="item.is_enabled" @change="modify(index)">
                                                    <el-radio :label="1">
                                                        有效
                                                    </el-radio>
                                                    <el-radio :label="0">
                                                        失效
                                                    </el-radio>
                                                </el-radio-group>
                                            </td>
                                            <template v-if="item.is_enabled === 1">
                                                <th>
                                                    生效时间
                                                </th>
                                                <td class="tdStyle">
                                                    <el-date-picker
                                                        v-model="item.effective_date"
                                                        type="datetime"
                                                        placeholder="选择生效时间"
                                                        value-format="yyyy-MM-dd HH:mm:ss"
                                                        @change="modify(index)"
                                                    >
                                                    </el-date-picker>
                                                </td>
                                            </template>
                                            <template v-else>
                                                <th>
                                                    失效时间
                                                </th>
                                                <td class="tdStyle">
                                                    <el-date-picker
                                                        v-model="item.cutoff_date"
                                                        type="datetime"
                                                        placeholder="选择失效时间"
                                                        value-format="yyyy-MM-dd HH:mm:ss"
                                                        @change="modify(index)"
                                                    >
                                                    </el-date-picker>
                                                </td>
                                            </template>
                                        </tr>
                                        <tr>
                                            <th colspan="4" class="tHeaderBtn">
                                                <el-button type="danger" class="el_right" @click="deleteFormList(item, index)">
                                                    删除
                                                </el-button>
                                            </th>
                                        </tr>
                                    </tbody>
                                </table>
                            </template>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
        <footer>
            <el-button @click="cancel">
                取消
            </el-button>
            <el-button type="primary" @click="preservation">
                保存
            </el-button>
            <el-button type="primary" @click="versionConfirmation">
                确认
            </el-button>
        </footer>
    </div>
</template>

<script>
// 全局事件
import { eventBus } from '@/common/eventBus.ts';

export default {
    // 原材料合同台账维护
    name: 'maintain',
    components: {},
    props: {
        extr: {
            type: [Object],
        },
    },
    data() {
        return {
            // 合同id
            ciid: '',
            // 品级列表
            mapGradeList: {},
            // 初始表单数据
            formData: {},
            // // 新增数据结构
            addFormData: {
                id: '',
                type_name: '',
                type_code: '',
                child_type_name: '',
                child_type_code: '',
                spec_code: '',
                spec_name: '',
                source_id: '',
                source_name: '',
                station_id: '',
                station_name: '',
                catalog_name: '',
                measure_unit: '',
                insurance_supply_type: null,
                insurance_supply: null,
                unit_price: null,
                tax_rate: null,
                transport_id: '',
                transport_name: '',
                transport_price: null,
                transport_rate: null,
                amount: null,
                money: null,
                delivery_date: '',
                remarks: '',
                effective_date: '',
                cutoff_date: '',
                ischanged: true,
                is_enabled: 1,
                is_delete: 0,
                type_dic_code: '',
                child_type_dic_code: '',
                spec_dic_code: '',
                grade_code: '',
                grade_name: '',
                is_exist_overweight: true,
                overweight_value: null,
                overweight_price: null,
                overweight_transport_price: null,
            },
            materials: [],
            // 当前选择下标
            fonmIndex: 0,
            // 是否以保存
            isPreservation: true,
        };
    },
    computed: {},
    // 监控data中的数据变化
    watch: {},
    // 方法集合
    methods: {
        // 关闭弹出层
        cancel() {
            this.$parent.hide();
        },
        // 初始化表单数据
        getInitData() {
            this.$axios
                .get(`/interfaceApi/procurement/contract/versions/getorcreateedit/${this.ciid}`)
                .then(res => {
                    this.formData = res;
                })
                .catch(error => {
                    this.$message.error(error.ErrorCode.Message);
                });
        },
        // 选择品级
        chooseGrade(item) {
            const gradeList = this.mapGradeList[this.getGradeKey(item)];
            for (const grade of gradeList) {
                if (grade.grade_code === item.grade_code) {
                    item.grade_name = grade.grade_name;
                    break;
                }
            }
        },
        // 获取品级列表 自定义key
        getGradeKey(item) {
            return item.type_dic_code + item.child_type_code + item.child_type_dic_code + item.spec_code + item.spec_dic_code;
        },
        // 获取品级列表数据
        getGradeList(item) {
            const params = {
                type_dic_code: item.type_dic_code,
                child_type_code: item.child_type_code,
                child_type_dic_code: item.child_type_dic_code,
                spec_code: item.spec_code,
                spec_dic_code: item.spec_dic_code,
            };
            this.$axios
                .post('/interfaceApi/procurement/quality/config/materialgrades', params)
                .then(res => {
                    if (res) {
                        const materialGrades = [];
                        res.forEach((item, index) => {
                            materialGrades.push({
                                ciid: this.formData.ciid,
                                grade_code: item.grade_code,
                                grade_name: item.grade_name,
                                unit_price: null,
                                overweight_price: null,
                                sort_num: index,
                            });
                        });
                        // const mapGradeList = Object.assign({}, this.mapGradeList);
                        // mapGradeList[this.getGradeKey(item)] = res;
                        // this.mapGradeList = mapGradeList;


                        this.$set(this.mapGradeList, this.getGradeKey(item), res);

                        // this.formData.materials[this.fonmIndex] = materialGrades;

                    }
                })
                .catch(error => {
                    this.$message.error(error.ErrorCode.Message);
                });
        },
        // 添加
        addForm() {
            this.formData.materials.push(JSON.parse(JSON.stringify(this.addFormData)));
        },
        // 选择原材料
        selectType(index, data) {
            if (data.is_enabled === 0) {
                return;
            }
            this.fonmIndex = index;
            this.$toast({
                title: true,
                text: '选择原材料',
                type: 'eject',
                width: '70%',
                t_url: 'tableList/index',
                extr: {
                    code: {
                        TableCode: 'cg_ht_xzycl',
                        QueryString: `supplier_id=${this.formData.supplier_id}`,
                        ViewCode: 'one',
                    },
                    selectTypeCallback: this.confirmSelectType,
                },
            });
        },
        // 选择原材料回调
        confirmSelectType(data) {
            this.formData.materials[this.fonmIndex].type_name = data.type_name;
            this.formData.materials[this.fonmIndex].type_code = data.type_code;
            this.formData.materials[this.fonmIndex].type_dic_code = data.type_dic_code;
            this.formData.materials[this.fonmIndex].child_type_name = data.child_type_name;
            this.formData.materials[this.fonmIndex].child_type_code = data.child_type_code;
            this.formData.materials[this.fonmIndex].child_type_dic_code = data.child_type_dic_code;
            this.formData.materials[this.fonmIndex].spec_code = data.spec_code;
            this.formData.materials[this.fonmIndex].spec_dic_code = data.spec_dic_code;
            this.formData.materials[this.fonmIndex].spec_name = data.spec_name;

            this.formData.materials[this.fonmIndex].grade_code = data.grade_code;
            this.formData.materials[this.fonmIndex].grade_name = data.grade_name;

            // 获取品级
            if (!this.mapGradeList[this.getGradeKey(data)]) {
                this.getGradeList(data);
            }
        },
        // 选择货源地
        selectSource(index, data) {
            if (data.is_enabled === 0) {
                return;
            }
            this.fonmIndex = index;
            if (this.formData.materials[index].type_code === '') {
                this.$message({
                    message: '请先选择原材料！',
                    type: 'warning',
                });
            } else {
                this.$toast({
                    title: true,
                    text: '选择货源地',
                    type: 'eject',
                    width: '70%',
                    t_url: 'tableList/index',
                    extr: {
                        code: {
                            TableCode: 'cg_hydxz_ycldl',
                            QueryString: `type_dic_code=${this.formData.materials[index].type_dic_code}`,
                            ViewCode: 'one',
                        },
                        selectTypeCallback: this.confirmSelectSource,
                    },
                });
            }
        },
        // 选择货源地回调
        confirmSelectSource(data) {
            this.formData.materials[this.fonmIndex].source_name = data.name;
            this.formData.materials[this.fonmIndex].source_id = data.id;
        },
        // 选择场站
        selectStation(index, data) {
            if (data.is_enabled === 0) {
                return;
            }
            this.fonmIndex = index;
            this.$toast({
                title: true,
                text: '选择供应站点',
                type: 'eject',
                width: '70%',
                t_url: 'tableList/index',
                extr: {
                    code: {
                        TableCode: 'cg_htxxb_xzcz',
                        ViewCode: 'one',
                    },
                    selectTypeCallback: this.confirmSelectStation,
                },
            });
        },
        // 选择场站回调
        confirmSelectStation(data) {
            this.formData.materials[this.fonmIndex].station_name = data.dept_name;
            this.formData.materials[this.fonmIndex].station_id = data.dept_id;
        },
        // 获取运输单位列表
        getTransports() {
            this.$axios
                .get(`/interfaceApi/procurement/contract/versions/gettransportunit/${this.ciid}`)
                .then(res => {
                    this.formData.transports = res;
                })
                .catch(error => {
                    this.$message.error(error.ErrorCode.Message);
                });
        },
        // 选择运输单位
        selectTransport(data) {
            const row = this.formData.transports.filter(item => item.transport_id === data.transport_id )[0];
            data.transport_name = row.transport_name;
            data.ischanged = true;
        },
        // 已修改
        modify(index) {
            this.formData.materials[index].ischanged = true;
        },
        // 删除
        deleteFormList(data, index) {
            if (data.id) {
                data.is_delete = 1;
                data.ischanged = true;
            } else {
                this.formData.materials.splice(index, 1);
            }
        },
        // 简单校验
        simpleValid() {
            const data = this.formData.materials.filter(item => item.id === '');
            let msg = '';
            for (const item of data) {
                if (!item.type_name) {
                    msg = '原材料不能为空';
                    break;
                }
                if (this.mapGradeList[this.getGradeKey(item)] && !item.grade_code) {
                    msg = '原材料品级不能为空';
                    break;
                }
                if (!item.source_name) {
                    msg = '货源地不能为空';
                    break;
                }
                if (!item.station_name) {
                    msg = '供应站点不能为空';
                    break;
                }
                if (item.is_exist_overweight && !item.overweight_value) {
                    msg = '超吨值不能为空';
                    break;
                }
                if (!item.unit_price) {
                    msg = '含税单价(不超吨)不能为空';
                    break;
                }
                if (item.is_exist_overweight && !item.overweight_price) {
                    msg = '含税单价(超吨)不能为空';
                    break;
                }
                if (item.transport_name && !item.transport_price) {
                    msg = '运输单价(不超吨)不能为空';
                    break;
                }
                if (item.transport_name && item.is_exist_overweight && !item.overweight_transport_price) {
                    msg = '运输单价(超吨)不能为空';
                    break;
                }
                if (!item.tax_rate) {
                    msg = '税率不能为空';
                    break;
                }
                if (item.is_enabled && !item.effective_date) {
                    msg = '生效时间不能为空';
                    break;
                }
                if (!item.is_enabled && !item.cutoff_date) {
                    msg = '失效时间不能为空';
                    break;
                }
            }
            if (msg) {
                this.$message.warning(msg);
                return false;
            }
            return true;
        },
        // 保存
        preservation() {
            if (!this.simpleValid()) {
                return;
            }
            this.$axios
                .post('/interfaceApi/procurement/contract/versions/grade/save/' + this.formData.id, this.formData.materials)
                .then(res => {
                    this.formData = res;
                    this.isPreservation = true;
                    this.$message({
                        message: '保存成功！',
                        type: 'success',
                    });

                })
                .catch(error => {
                    this.$message.error(error.ErrorCode.Message);
                });
        },
        // 确认
        versionConfirmation() {
            this.formData.materials.forEach(item => {
                if (item.ischanged) {
                    this.isPreservation = false;
                }
            });
            if (this.isPreservation === false) {
                this.$message({
                    message: '请先保存！',
                    type: 'warning',
                });
            } else {
                this.$axios
                    .get(`/interfaceApi/procurement/contract/versions/confirm/${this.formData.id}`)
                    .then(() => {
                        this.$message({
                            message: '版本已确认！',
                            type: 'success',
                        });
                        this.$parent.hide();
                        eventBus.$emit('updateTable');
                    })
                    .catch(error => {
                        this.$message.error(error.ErrorCode.Message);
                    });
            }
        },
    },
    // 生命周期 - 创建完成（可以访问当前this实例）
    created() {
        // 合同id
        this.ciid = this.extr.ciid;

        // 初始化表单数据
        this.getInitData();
        this.getTransports();
    },
    // 生命周期 - 挂载完成（可以访问DOM元素）
    mounted() {

    },
    // 如果页面有keep-alive缓存功能，这个函数会触发
    activated() { },
};
</script>
<style lang='stylus'>
.maintain_nx
    height 100%
    padding 0.15rem
    .content_box
        width 100%
        height calc(100% - 0.6rem)
        padding-bottom 0.1rem
        overflow-y auto
        table
            width 100%
            border-collapse collapse
            th, td
                height 0.42rem
                font-size 0.16rem
                position relative
                border 1px solid #D7D7D7
                font-weight 400
                text-align left
                white-space nowrap
            th
                color #002B94
                background-color #F6F8FC
                width 2rem
                text-indent 0.2rem
                &.tHeader
                    font-size 0.18rem
                    background #EDF0F5
                    text-align center
                &.tHeaderBtn
                    h4
                        font-size 0.18rem
                        line-height 0.42rem
                    button
                        width 0.6rem
                        height 0.3rem
                        line-height 0.3rem
                        text-align center
                        padding 0
                        margin 0.06rem 0.1rem
            td
                color #333
                width 5rem
                padding-left 0.2rem
                &.tdStyle
                    padding-left 0
                table
                    margin  0.1rem
                    width calc(100% - 0.2rem)
                    &:nth-of-type(even)
                        th,td
                            border 1px solid #D6CEC9
                        th
                            color #823802
                            background-color #FCF9F6
                    &.invalid
                        tr
                            opacity 0.5
                            &.state
                                opacity 1
                .el-input, .el-select
                    width 100% !important
                    height 0.42rem
                    font-size 0.16rem
                    .el-input__inner
                        height 0.42rem
                        line-height 0.42rem
                        border 0;
                    .el-input-group__append
                        border 0;
    footer
        height 0.5rem
        text-align center
        margin-top 0.1rem
        button
            width 2rem
            margin 0 0.3rem
            height 0.42rem
            font-size 0.16rem
            line-height 0.42rem
            padding 0
    .overWeightInput
        padding-left 0.2rem;
        display flex
        align-items center
        >>> input
            height 0.28rem;
        .el-radio
            margin-right 0.14rem;
        .el-input
            display inline-block
            .el-input__inner
                border-radius 0
                height 0.34rem!important;
                border-color transparent
                border-bottom 1px solid #000!important
                text-align center
    input::-webkit-outer-spin-button, input::-webkit-inner-spin-button
        -webkit-appearance: none!important;
    input[type="number"]
        -moz-appearance: textfield!important;
</style>
